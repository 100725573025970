<script>
export default {
    props:{
        type:String,
        status:String,
    },
    computed:{
        data(){
            if(this.type=="blog"){
                return {
                    "P": {style:"success",label:"Publish"},
                    "D": {style:"warning",label:"Draft"},
                }[this.status.toUpperCase()] || {}
            }
            if(this.type=="sold"){
                return {
                    "P": {style:"danger",label:"Sold Out"},
                    "D": {style:"info",label:"Availabel"},
                }[this.status.toUpperCase()] || {}
            }
            if(this.type=="approve"){
                return {
                    "P": {style:"info",label:"Need Verified"},
                    "N": {style:"warning",label:"Rejected"},
                    "Y": {style:"success",label:"Verify"},
                }[this.status.toUpperCase()] || {}
            }
            if(this.type=="delivery_proccess"){
                return {
                    "N": {style:"info",label:"Menunggu Transfer"},
                    "T": {style:"warning",label:"Upload Transfer"},
                    "V": {style:"success",label:"Verify Transfer"},
                    "P": {style:"success",label:"Proses Packing"},
                    "D": {style:"success",label:"Dikirim"},
                    "R": {style:"success",label:"Received by Customer"},
                }[this.status.toUpperCase()] || {}
            }
            return {
                "Y": {style:"success",label:"Active"},
                "N": {style:"warning",label:"Inactive"},
                "D": {style:"info",label:"Default"},
            }[this.status.toUpperCase()]
        }
    }
}
</script>

<template>
    <label :class="'label label-'+(data.style)">{{data.label}}</label>
</template>